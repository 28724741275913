import { render, staticRenderFns } from "./Autocomplete.vue?vue&type=template&id=b279ef98&scoped=true&"
import script from "./Autocomplete.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Autocomplete.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Autocomplete.vue?vue&type=style&index=0&id=b279ef98&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b279ef98",
  null
  
)

export default component.exports